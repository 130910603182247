
import platform from 'geeky-platform';

const themes = {
    dark: {
        'home-background': 'rgb(35, 35, 40)',
        'home-text-main': 'rgba(255, 255, 255, 0.92)',
        'home-text-secondary': 'rgba(255, 255, 255, 0.5)',
        'home-card-background': 'rgba(43, 44, 50, 1.0)',
        'home-card-border': 'rgba(255, 255, 255, 0.05)',
        'home-text-accent': 'rgba(248, 219, 179, 1.0)',
        'home-text-good': 'rgba(131, 244, 220, 1.0)',
        'home-text-bad': 'rgba(253, 136, 113, 1.0)',
        'home-border-list': 'rgba(255, 255, 255, 0.05)',
        // 'background': 'rgb(26, 25, 25)',
        'background': 'rgb(35, 35, 40)',
        'background-blur': 'rgba(26, 25, 25, 0.7)',
        'background-blur-b': 'rgba(37, 34, 43, 0.7)',
        'background-r': 'rgb(26, 25, 25)',
        'card-r': 'rgb(37, 38, 40)',
        'button-bg': 'rgba(120, 120, 120, 0.1)',
        'button-text': 'rgba(255, 255, 255, 0.9)',
        // 'background-r': 'rgb(25, 25, 36)',
        'b1': 'rgb(46, 43, 42)',
        'h1': 'rgb(255, 255, 255)',
        'h15': 'rgba(255, 255, 255, 0.9)',
        'h2': 'rgba(255, 255, 255, 0.7)',
        'h3': 'rgba(255, 255, 255, 0.5)',
        'i1': 'rgb(255, 255, 255)',
        'i1-border': '2px solid rgba(255, 255, 255, 0.1)',
        'i1-border-focus': '2px solid rgba(255, 255, 255, 0.3)',
        'stat-background-a': 'rgba(120, 155, 234, 0.12)',
        'stat-background-b': 'rgba(234, 171, 120, 0.12)',
        'stat-background-c': 'rgba(103, 202, 163, 0.12)',
        'stat-background-d': 'rgba(245, 140, 136, 0.12)',
        'stat-text-a': 'rgba(120, 155, 234, 1.0)',
        'stat-text-b': 'rgba(234, 171, 120, 1.0)',
        'stat-text-c': 'rgba(103, 202, 163, 1.0)',
        'stat-text-d': 'rgba(245, 140, 136, 1.0)',
        'stat-subtext-a': 'rgba(120, 155, 234, 1.0)',
        'stat-subtext-b': 'rgba(234, 171, 120, 1.0)',
        'stat-subtext-c': 'rgba(103, 202, 163, 1.0)',
        'stat-subtext-d': 'rgba(245, 140, 136, 1.0)',
        'wheel-fill': 'rgba(103, 202, 163, 1.0)',
        'wheel-bg': 'rgba(103, 202, 163, 0.09)',
        'wheel-text': 'rgba(103, 202, 163, 1.0)',
        'bg-a': 'rgba(233, 160, 72, 0.12)',
        'bg-b': 'rgba(161, 156, 155, 0.12)',
        'bg-c': 'rgba(222, 193, 153, 0.12)',
        'bg-d': 'rgba(245, 140, 136, 0.0)',
        'fg-0-a': 'rgba(249, 197, 125, 1.0)',
        'fg-0-b': 'rgba(161, 156, 155, 1.0)',
        'fg-0-c': 'rgba(222, 193, 153, 1.0)',
        'fg-0-d': 'rgba(255, 255, 255, 0.5)',
        'fg-1-a': 'rgba(255, 255, 255, 0.8)',
        'fg-1-b': 'rgba(255, 255, 255, 0.8)',
        'fg-1-c': 'rgba(255, 255, 255, 0.8)',
        'fg-1-d': 'rgba(255, 255, 255, 0.8)',
        'fg-2-a': 'rgba(255, 255, 255, 0.5)',
        'fg-2-b': 'rgba(255, 255, 255, 0.5)',
        'fg-2-c': 'rgba(255, 255, 255, 0.5)',
        'fg-2-d': 'rgba(255, 255, 255, 0.5)',
        'back': 'url(/arrow-light.svg)',
        'close': 'url(/close-light.svg)',
        'step-up': 'url(/step-up-light.svg)',
        'step-down': 'url(/step-down-light.svg)',
        'plus': 'url(/plus-light.svg)',
        'minus': 'url(/minus-light.svg)',
        'edit': 'url(/edit-light.svg)',
        'standard-border': '1px solid rgba(255, 255, 255, 0.1)',
        'lock': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%2367CAA3'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z'/%3E%3C/svg%3E")`,
        'unlock': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FFFFFF'%3E%3Cg fill='none'%3E%3Cpath d='M0 0h24v24H0V0z'/%3E%3Cpath d='M0 0h24v24H0V0z' opacity='.87'/%3E%3C/g%3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z'/%3E%3C/svg%3E")`,
        'nav-close': `url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FFFFFF'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E")`,
        'arrow': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='chevron-right' transform='translate(11.000000, 5.000000)' stroke='%23FFFFFF' stroke-width='2'%3E%3Cpolyline id='Path' points='0 10 5 5 0 0'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'spotlight': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='cone' transform='translate(3.000000, 2.000000)' stroke='%23F8DBB3' stroke-width='2'%3E%3Cpath d='M14,13 L7.70786517,0.443265387 C7.57644887,0.17217953 7.30148968,0 7,0 C6.69851032,0 6.42355113,0.17217953 6.29213483,0.443265387 L0,13' id='Path'%3E%3C/path%3E%3Cellipse id='Oval' cx='7' cy='13.5' rx='7' ry='2.5'%3E%3C/ellipse%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'points': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='coins' transform='translate(1.000000, 1.000000)' stroke='%23F8DBB3' stroke-width='2'%3E%3Ccircle id='Oval' cx='5.5' cy='5.5' r='5.5'%3E%3C/circle%3E%3Cpath d='M14.314881,7 C16.7741065,7.91937334 18.2811348,10.4126668 17.9561211,13.0242268 C17.6311074,15.6357868 15.5592264,17.6812163 12.9500992,17.9663372 C10.3409721,18.251458 7.87835843,16.7015501 7,14.2214926' id='Path'%3E%3C/path%3E%3Cpolyline id='Path' points='4 4 5 4 5 7'%3E%3C/polyline%3E%3Cpolyline id='Path' points='13.5035461 11 14 11.6033994 12 14'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'steps': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='footprints' transform='translate(1.000000, 1.000000)' stroke='%23F8DBB3' stroke-width='2'%3E%3Cpath d='M1.00055096,12.25 L1.00055096,10.1675 C1.00055096,8.3125 -0.0293544618,7.4375 0.000642783396,5.25 C0.0306400286,2.87 1.49050596,0 4.50022957,0 C6.37005785,0 7,1.575 7,3.0625 C7,5.78375 5.00018365,8.015 5.00018365,10.6575 L5.00018365,12.25 C5.00018365,13.2164983 4.10483538,14 3.0003673,14 C1.89589923,14 1.00055096,13.2164983 1.00055096,12.25 Z' id='Path'%3E%3C/path%3E%3Cpath d='M16.999449,16.25 L16.999449,14.1675 C16.999449,12.3125 18.0293545,11.4375 17.9993572,9.25 C17.96936,6.87 16.509494,4 13.4997704,4 C11.6299421,4 11,5.575 11,7.0625 C11,9.78375 12.9998163,12.015 12.9998163,14.6575 L12.9998163,16.25 C12.9998163,17.2164983 13.8951646,18 14.9996327,18 C16.1041008,18 16.999449,17.2164983 16.999449,16.25 Z' id='Path'%3E%3C/path%3E%3Cline x1='13' y1='13.5' x2='17' y2='13.5' id='Path'%3E%3C/line%3E%3Cline x1='1' y1='9.5' x2='5' y2='9.5' id='Path'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'award': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='trophy' transform='translate(2.000000, 2.000000)' stroke='%23F8DBB3' stroke-width='2'%3E%3Cpath d='M3.12195122,5.46341463 L1.95121951,5.46341463 C0.873590732,5.46341463 0,4.5898239 0,3.51219512 C0,2.43456634 0.873590732,1.56097561 1.95121951,1.56097561 L3.12195122,1.56097561' id='Path'%3E%3C/path%3E%3Cpath d='M12.4878049,5.46341463 L13.6585366,5.46341463 C14.7361654,5.46341463 15.6097561,4.5898239 15.6097561,3.51219512 C15.6097561,2.43456634 14.7361654,1.56097561 13.6585366,1.56097561 L12.4878049,1.56097561' id='Path'%3E%3C/path%3E%3Cline x1='1.56097561' y1='15.6097561' x2='14.0487805' y2='15.6097561' id='Path'%3E%3C/line%3E%3Cpath d='M6.24390244,9.88097561 L6.24390244,11.7073171 C6.24390244,12.1365854 5.87707317,12.4721951 5.48682927,12.6517073 C4.56585366,13.0731707 3.90243902,14.2360976 3.90243902,15.6097561' id='Path'%3E%3C/path%3E%3Cpath d='M9.36585366,9.88097561 L9.36585366,11.7073171 C9.36585366,12.1365854 9.73268293,12.4721951 10.1229268,12.6517073 C11.0439024,13.0731707 11.7073171,14.2360976 11.7073171,15.6097561' id='Path'%3E%3C/path%3E%3Cpath d='M12.4878049,0 L3.12195122,0 L3.12195122,5.46341463 C3.12195122,8.04972371 5.21856898,10.1463415 7.80487805,10.1463415 C10.3911871,10.1463415 12.4878049,8.04972371 12.4878049,5.46341463 L12.4878049,0 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'lightbulb': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='trending-up-(1)' transform='translate(1.000000, 5.000000)' stroke='%23F8DBB3' stroke-width='2'%3E%3Cpolyline id='Path' points='18 0 10.35 7.65 5.85 3.15 0 9'%3E%3C/polyline%3E%3Cpolyline id='Path' points='13 0 18 0 18 5'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'gift': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='gift-(1)' transform='translate(1.000000, 2.000000)' stroke='%23F8DBB3' stroke-width='2'%3E%3Crect id='Rectangle' x='0' y='5' width='17' height='4' rx='1'%3E%3C/rect%3E%3Cline x1='8.5' y1='5' x2='8.5' y2='17' id='Path'%3E%3C/line%3E%3Cpath d='M15,9 L15,15.2222222 C15,16.2040618 14.1685288,17 13.1428571,17 L3.85714286,17 C2.83147118,17 2,16.2040618 2,15.2222222 L2,9' id='Path'%3E%3C/path%3E%3Cpath d='M4.32142857,5 C3.03933897,5 2,3.88081042 2,2.5002201 C2,1.11962979 3.03933897,0.000440204131 4.32142857,0.000440204131 C6.15464347,-0.033953345 7.81260701,1.94975999 8.5,5 C9.18739299,1.94975999 10.8453565,-0.033953345 12.6785714,0.000440204131 C13.960661,0.000440204131 15,1.11962979 15,2.5002201 C15,3.88081042 13.960661,5 12.6785714,5' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
    },
    light: {
        'home-background': 'rgb(255, 255, 255)',
        'home-text-main': 'rgba(43, 44, 50, 1.0)',
        'home-text-secondary': 'rgba(43, 44, 50, 0.5)',
        'home-card-background': 'rgba(250, 250, 250, 1.0)',
        'home-card-border': 'rgba(250, 250, 250, 1.0)',
        // 'home-card-border': 'rgba(43, 44, 50, 0.05)',
        'home-text-accent': 'rgba(207, 187, 132, 1.0)',
        'home-text-good': 'rgba(119, 173, 143, 1.0)',
        'home-text-bad': 'rgba(116, 240, 171, 1.0)',
        'home-border-list': 'rgba(43, 44, 50, 0.05)',
        // 'background': 'rgb(254, 248, 246)',
        'background': 'rgb(255, 255, 255)',
        'background-blur': 'rgba(254, 248, 246, 0.7)',
        'background-blur-b': 'rgba(254, 248, 246, 0.7)',
        'background-r': 'rgb(254, 248, 246)',
        'button-bg': 'rgba(48, 45, 56, 0.1)',
        'button-text': 'rgba(51, 51, 51, 0.7)',
        'card-r': 'rgb(247, 236, 235)',
        'b1': 'rgb(232, 230, 230)',
        'h1': 'rgb(51, 51, 51)',
        'h15': 'rgba(51, 51, 51, 0.9)',
        'h2': 'rgba(51, 51, 51, 0.6)',
        'h3': 'rgba(51, 51, 51, 0.45)',
        'i1': 'rgb(51, 51, 51)',
        'i1-border': '2px solid rgba(51, 51, 51, 0.1)',
        'i1-border-focus': '2px solid rgba(51, 51, 51, 0.3)',
        'stat-background-a': 'rgba(120, 155, 234, 0.12)',
        'stat-background-b': 'rgba(234, 171, 120, 0.12)',
        'stat-background-c': 'rgba(103, 202, 163, 0.12)',
        'stat-background-d': 'rgba(245, 140, 136, 0.12)',
        'stat-text-a': 'rgba(120, 155, 234, 1.0)',
        'stat-text-b': 'rgba(234, 171, 120, 1.0)',
        'stat-text-c': 'rgba(103, 202, 163, 1.0)',
        'stat-text-d': 'rgba(245, 140, 136, 1.0)',
        'stat-subtext-a': 'rgba(0, 0, 0, 0.25)',
        'stat-subtext-b': 'rgba(0, 0, 0, 0.25)',
        'stat-subtext-c': 'rgba(0, 0, 0, 0.25)',
        'stat-subtext-d': 'rgba(0, 0, 0, 0.25)',
        'wheel-fill': 'rgba(103, 202, 163, 1.0)',
        'wheel-bg': 'rgba(245, 243, 242, 1.0)',
        'wheel-text': 'rgb(51, 51, 51)',
        'bg-a': 'rgba(233, 160, 72, 0.12)',
        'bg-b': 'rgba(161, 156, 155, 0.12)',
        'bg-c': 'rgba(222, 193, 153, 0.12)',
        'bg-d': 'rgba(245, 140, 136, 0.0)',
        'fg-0-a': 'rgba(249, 197, 125, 1.0)',
        'fg-0-b': 'rgba(161, 156, 155, 1.0)',
        'fg-0-c': 'rgba(222, 193, 153, 1.0)',
        'fg-0-d': 'rgba(0, 0, 0, 0.2)',
        'fg-1-a': 'rgba(0, 0, 0, 0.6)',
        'fg-1-b': 'rgba(0, 0, 0, 0.6)',
        'fg-1-c': 'rgba(0, 0, 0, 0.6)',
        'fg-1-d': 'rgba(0, 0, 0, 0.6)',
        'fg-2-a': 'rgba(0, 0, 0, 0.2)',
        'fg-2-b': 'rgba(0, 0, 0, 0.2)',
        'fg-2-c': 'rgba(0, 0, 0, 0.2)',
        'fg-2-d': 'rgba(0, 0, 0, 0.2)',
        'back': 'url(/arrow-dark.svg)',
        'close': 'url(/close-dark.svg)',
        'step-up': 'url(/step-up-dark.svg)',
        'step-down': 'url(/step-down-dark.svg)',
        'plus': 'url(/plus-dark.svg)',
        'minus': 'url(/minus-dark.svg)',
        'edit': 'url(/edit-dark.svg)',
        'standard-border': '1px solid rgba(51, 51, 51, 0.1)',
        'lock': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23333333'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z'/%3E%3C/svg%3E")`,
        'unlock': `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%2367CAA3'%3E%3Cg fill='none'%3E%3Cpath d='M0 0h24v24H0V0z'/%3E%3Cpath d='M0 0h24v24H0V0z' opacity='.87'/%3E%3C/g%3E%3Cpath d='M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z'/%3E%3C/svg%3E")`,
        'nav-close': `url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23777777'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'/%3E%3C/svg%3E")`,
        'arrow': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='chevron-right' transform='translate(11.000000, 5.000000)' stroke='%232B2C32' stroke-width='2'%3E%3Cpolyline id='Path' points='0 10 5 5 0 0'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'spotlight': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='cone' transform='translate(3.000000, 2.000000)' stroke='%23CFBB84' stroke-width='2'%3E%3Cpath d='M14,13 L7.70786517,0.443265387 C7.57644887,0.17217953 7.30148968,0 7,0 C6.69851032,0 6.42355113,0.17217953 6.29213483,0.443265387 L0,13' id='Path'%3E%3C/path%3E%3Cellipse id='Oval' cx='7' cy='13.5' rx='7' ry='2.5'%3E%3C/ellipse%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'points': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='coins' transform='translate(1.000000, 1.000000)' stroke='%23CFBB84' stroke-width='2'%3E%3Ccircle id='Oval' cx='5.5' cy='5.5' r='5.5'%3E%3C/circle%3E%3Cpath d='M14.314881,7 C16.7741065,7.91937334 18.2811348,10.4126668 17.9561211,13.0242268 C17.6311074,15.6357868 15.5592264,17.6812163 12.9500992,17.9663372 C10.3409721,18.251458 7.87835843,16.7015501 7,14.2214926' id='Path'%3E%3C/path%3E%3Cpolyline id='Path' points='4 4 5 4 5 7'%3E%3C/polyline%3E%3Cpolyline id='Path' points='13.5035461 11 14 11.6033994 12 14'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'steps': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='footprints' transform='translate(1.000000, 1.000000)' stroke='%23CFBB84' stroke-width='2'%3E%3Cpath d='M1.00055096,12.25 L1.00055096,10.1675 C1.00055096,8.3125 -0.0293544618,7.4375 0.000642783396,5.25 C0.0306400286,2.87 1.49050596,0 4.50022957,0 C6.37005785,0 7,1.575 7,3.0625 C7,5.78375 5.00018365,8.015 5.00018365,10.6575 L5.00018365,12.25 C5.00018365,13.2164983 4.10483538,14 3.0003673,14 C1.89589923,14 1.00055096,13.2164983 1.00055096,12.25 Z' id='Path'%3E%3C/path%3E%3Cpath d='M16.999449,16.25 L16.999449,14.1675 C16.999449,12.3125 18.0293545,11.4375 17.9993572,9.25 C17.96936,6.87 16.509494,4 13.4997704,4 C11.6299421,4 11,5.575 11,7.0625 C11,9.78375 12.9998163,12.015 12.9998163,14.6575 L12.9998163,16.25 C12.9998163,17.2164983 13.8951646,18 14.9996327,18 C16.1041008,18 16.999449,17.2164983 16.999449,16.25 Z' id='Path'%3E%3C/path%3E%3Cline x1='13' y1='13.5' x2='17' y2='13.5' id='Path'%3E%3C/line%3E%3Cline x1='1' y1='9.5' x2='5' y2='9.5' id='Path'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'award': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='trophy' transform='translate(2.000000, 2.000000)' stroke='%23F8DBB3' stroke-width='2'%3E%3Cpath d='M3.12195122,5.46341463 L1.95121951,5.46341463 C0.873590732,5.46341463 0,4.5898239 0,3.51219512 C0,2.43456634 0.873590732,1.56097561 1.95121951,1.56097561 L3.12195122,1.56097561' id='Path'%3E%3C/path%3E%3Cpath d='M12.4878049,5.46341463 L13.6585366,5.46341463 C14.7361654,5.46341463 15.6097561,4.5898239 15.6097561,3.51219512 C15.6097561,2.43456634 14.7361654,1.56097561 13.6585366,1.56097561 L12.4878049,1.56097561' id='Path'%3E%3C/path%3E%3Cline x1='1.56097561' y1='15.6097561' x2='14.0487805' y2='15.6097561' id='Path'%3E%3C/line%3E%3Cpath d='M6.24390244,9.88097561 L6.24390244,11.7073171 C6.24390244,12.1365854 5.87707317,12.4721951 5.48682927,12.6517073 C4.56585366,13.0731707 3.90243902,14.2360976 3.90243902,15.6097561' id='Path'%3E%3C/path%3E%3Cpath d='M9.36585366,9.88097561 L9.36585366,11.7073171 C9.36585366,12.1365854 9.73268293,12.4721951 10.1229268,12.6517073 C11.0439024,13.0731707 11.7073171,14.2360976 11.7073171,15.6097561' id='Path'%3E%3C/path%3E%3Cpath d='M12.4878049,0 L3.12195122,0 L3.12195122,5.46341463 C3.12195122,8.04972371 5.21856898,10.1463415 7.80487805,10.1463415 C10.3911871,10.1463415 12.4878049,8.04972371 12.4878049,5.46341463 L12.4878049,0 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'lightbulb': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='lightbulb' transform='translate(5.000000, 1.000000)' stroke='%23F8DBB3' stroke-width='2'%3E%3Cpath d='M8.25,11 C8.43333333,10.0833333 8.89166667,9.44166667 9.625,8.70833333 C10.5416667,7.88333333 11,6.69166667 11,5.5 C11,2.46243388 8.53756612,0 5.5,0 C2.46243388,0 0,2.46243388 0,5.5 C0,6.41666667 0.183333333,7.51666667 1.375,8.70833333 C2.01666667,9.35 2.56666667,10.0833333 2.75,11' id='Path'%3E%3C/path%3E%3Cline x1='3' y1='14.5' x2='8' y2='14.5' id='Path'%3E%3C/line%3E%3Cline x1='3' y1='17.5' x2='8' y2='17.5' id='Path'%3E%3C/line%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
        'gift': `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='20px' height='20px' viewBox='0 0 20 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='gift-(1)' transform='translate(1.000000, 2.000000)' stroke='%23F8DBB3' stroke-width='2'%3E%3Crect id='Rectangle' x='0' y='5' width='17' height='4' rx='1'%3E%3C/rect%3E%3Cline x1='8.5' y1='5' x2='8.5' y2='17' id='Path'%3E%3C/line%3E%3Cpath d='M15,9 L15,15.2222222 C15,16.2040618 14.1685288,17 13.1428571,17 L3.85714286,17 C2.83147118,17 2,16.2040618 2,15.2222222 L2,9' id='Path'%3E%3C/path%3E%3Cpath d='M4.32142857,5 C3.03933897,5 2,3.88081042 2,2.5002201 C2,1.11962979 3.03933897,0.000440204131 4.32142857,0.000440204131 C6.15464347,-0.033953345 7.81260701,1.94975999 8.5,5 C9.18739299,1.94975999 10.8453565,-0.033953345 12.6785714,0.000440204131 C13.960661,0.000440204131 15,1.11962979 15,2.5002201 C15,3.88081042 13.960661,5 12.6785714,5' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
    }
};

const toggleTheme = mode => {
    const theme = (localStorage.getItem('theme') || 'light') === 'light' ? 'dark' : 'light';
    changeTheme(theme);
};

const changeTheme = mode => {
    updateProperties(themes[mode]);
    localStorage.setItem('theme', mode);
    platform.request({ id: 'changeAppearance', data: { mode }});
};

const configure = () => {
    const mode = 'dark'; //localStorage.getItem('theme') || 'dark';
    updateProperties(themes[mode]);
    platform.request({ id: 'changeAppearance', data: { mode }});
};

const updateProperties = props => {
    for (const property in props) {
        const value = props[property];
        const style = document.documentElement.style;
        style.setProperty('--appearance-' + property, value);
    }
};

const appearanceClass = (name, style) => name + ' ' + style;

export default { changeTheme, configure, toggleTheme, appearanceClass };
