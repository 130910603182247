
import HomeCard from '../HomeCard';
import style from './style.module.css';

const HomeDataPoint = props => {

    const highlight = () => {
        if (!props.highlightValue) return null;
        const color = props.highlightColor ? (' ' + props.highlightColor) : ' home-text-secondary';
        return <div className={style.highlight + color}>{props.highlightValue || ''}</div>
    };

    return (
        <HomeCard title={props.title || 'Data'} icon={props.icon || ''} onSelect={props.onSelect}>
            <div className={style.value + ' home-text-main'}>{props.value || ''}</div>
            <div className={style.info}>
                {highlight()}
                <div className={style.secondary + ' home-text-secondary'}>{props.secondary || ''}</div>
            </div>
        </HomeCard>
    );

};

export default HomeDataPoint;
