
import React, { useState, useEffect } from 'react';
import style from './style.module.css';
import Navigation from '../Navigation';
import api from '../../api'
import appearance from '../../utils/appearance';
import conditional from '../../utils/conditional';
import authStorage from '../../utils/authStorage';
import ProgressCircle from '../ProgressCircle';
import ShareGoalProgress from '../ShareGoalProgress';
import NotificationModal from '../NotificationModal';
import notificationUtils from './notifications';
import publisher from '../../utils/publisher';
import HistoryModal from '../HistoryModal'
import RewardsBanner from '../RewardsBanner';
import ContactModal from '../ContactModal';
import HomeDataPoint from '../HomeDataPoint';
import HomeLeaderboard from '../HomeLeaderboard';
const Home = props => {

    const [showShare, setShowShare] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [showWalkHistory, setShowWalkHistory] = useState(false);
    const [showPointsHistory, setShowPointsHistory] = useState(false);
    const [homeLoaded, setHomeLoaded] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [leaderboardFeatured, setLeaderboardFeatured] = useState([]);
    const [leaderboardGroup, setLeaderboardGroup] = useState([]);
    const [groupPoints, setGroupPoints] = useState(0);
    const fetchData = props.fetchData || {};

    const headerGoal = (fetchData.goalProgress || {}).default || {};
    const fetchSteps = fetchData.steps || [];
    const fetchPoints = fetchData.points || [];
    const fetchRank = fetchData.groupRank || [];

    const fetchContact = async () => {
        const prompt = localStorage.getItem('contacted') === 'true';
        if (!prompt) setShowContact(true);
    };

    const fetchLeaderboards = async () => {
        try {
            const featured = await api.groups.fetchFeatured();
            setLeaderboardFeatured(featured);
        } catch (e) {

        }

        try {
             const profile = await api.accounts.fetchProfile();
            const group = profile.acceptedGroups[0];

            const local = await api.groups.fetchLeaderboards(group._id);
            setLeaderboardGroup(local);
        } catch (e) {}

        try {
            const profile = await api.accounts.fetchProfile();
            const group = profile.acceptedGroups[0];

            const global = await api.groups.fetchGlobals();
            const found = global.filter(data => data._id === group._id);
            if (found[0]) setGroupPoints(found[0].quarterPoints || 0);
       } catch (e) {}

        // setLeaderboardFeatured([{ name: 'Name', quarterPoints: 1337, userCount: 3 }]);
    };

    useEffect(() => { 
        if (!fetchData || !Object.keys(fetchData).length) return;
        setNotifications(notificationUtils.generate(fetchData, props.profile)) 
        setTimeout(() => {
            setHomeLoaded(true);
        }, 6000);
        fetchContact();
        fetchLeaderboards();
    }, [fetchData]);

    const stats = [
        {
            key: 'a',
            name: 'Walk',
            number: ((fetchSteps[0] || {}).value || 0).toLocaleString(),
            metric: 'steps',
            link: () => setShowWalkHistory(true)
        },
        {
            key: 'b',
            name: 'This Quarter',
            number: (fetchData.quarterPoints || 0).toLocaleString(),
            metric: 'points',
            link: () => props.history.push('/badges')
        },
        {
            key: 'c',
            name: 'Daily Points',
            number: ((fetchPoints[0] || {}).value || 0).toLocaleString(),
            metric: 'earned',
            link: () => setShowPointsHistory(true)
        },
        {
            key: 'd',
            name: 'Rank',
            number: fetchRank.length ? '#' + ((fetchRank[0] || {}).rank || 0).toLocaleString() : 'N/A',
            metric: 'in group',
            link: () => props.history.push('/groups')
        }
    ];
    // const waitForThis = async _ => {
    //     const result = await api.accounts.DeleteLinkedAcc()
    //     const resultSource = await api.accounts.RemoveFitSource()
    //     props.resync();
    // }
    // waitForThis();

    const shareHeaderGoal = async () => {
        const percentage = Math.round((headerGoal.percentage || 0) * 100);

        const shareData = {
            type: percentage < 100 ? 'goals-behind' : 'goals-ahead',
            goalType: 'default'
        };

        const link = await api.links.add(shareData);
        publisher.publish('shareOptions', link);
    };

    const box = stat => (
        <div className={style.box + ' stat-background-' + stat.key + ' ' + style[stat.key]} onClick={() => stat.link ? stat.link() : console.log('no-link')}>
            <div className={style.label + ' stat-subtext-' + stat.key}>{stat.name}</div>
            <div className={style.stat}>
                <div className={style.number + ' stat-text-' + stat.key}>{stat.number}</div>
                <div className={style.metric + ' stat-subtext-' + stat.key}>{stat.metric}</div>
            </div>
        </div>
    );

    // const reward = stat => (
    //     <div className={style.box + ' stat-background-' + stat.key + ' ' + style[stat.key]} onClick={() => stat.link ? stat.link() : console.log('no-link')}>
    //         <div className={style.label + ' stat-subtext-' + stat.key}>{stat.name}</div>
    //         <div className={style.stat}>
    //             <div className={style.number + ' stat-text-' + stat.key}>{stat.number}</div>
    //             <div className={style.metric + ' stat-subtext-' + stat.key}>{stat.metric}</div>
    //         </div>
    //     </div>
    // );

    const greeting = () => {
        const percentage = Math.round((headerGoal.percentage || 0) * 100);
        const name = (props.profile.firstName || '').trim();

        return percentage > 50 ? 
            { main: `Great work, ${name}!`, secondary: `You hit ${percentage}% of your goal.` } : 
            { main: `Keep going, ${name}!`, secondary: `You're at ${percentage}% of your goal.` };
    };

    const quarter = () => Math.floor(((new Date()).getMonth() + 3) / 3);

    const changeSteps = () => {
        if (!fetchData || !fetchData.steps || fetchData.steps.length < 2) return '';

        const from = fetchData.steps[1].value || 0;
        const to = fetchData.steps[0].value || 0;
        if (from < 50 || to < 50) return '';

        const change = (to - from) / from;
        const percent = !isNaN(change) && isFinite(change) ? parseInt(change * 100) : '';
        return `${percent > 0 ? '+' : ''}${percent}%`;
    };

    // const tipsClass = conditional('tips', style, { visible: props.fetchData !== null })
    const statsClass = conditional('stats', style, { visible: props.fetchData !== null })
    // const wheelTextClass = conditional('wheelText', style, { visible: props.fetchData !== null })
    // const starClass = conditional('giftBox', style, { visible: props.fetchData !== null }); // props.fetchData !== null
    const starClass = conditional('giftBox', style, { visible: true }); // props.fetchData !== null

    // const percentage = Math.round((headerGoal.percentage || 0) * 100);
    // const percentTitle = percentage > 50 ? 'Great work, ' + props.profile.firstName + '!' : 'Keep going, ' + props.profile.firstName + '!'
    // const percentTip = percentage > 50 ? 'We\'re blown away!' : 'There\'s still time, keep going!'
    const auth = authStorage.get();
    return (
        <Navigation hideNavigationBar>
            {/* <div className={style.lightSwitch + ' stat-background-b'} onClick={() => appearance.toggleTheme()}><span className="material-icons stat-text-b">wb_sunny</span></div> */}
            {/* <div className={starClass} onClick={() => props.showRewards()}></div> */}
            {/* <div className={style.share + ' stat-background-c'} onClick={shareHeaderGoal}><span className={'stat-text-c'}>Share It</span></div> */}
            <div className={style.content}>
                <div className={style.greeting}>
                    <div className={style.greetingTop}>
                        <div className={style.greetingIcon} />
                        <div className={style.greetingButton} style={{ backgroundImage: `var(--appearance-gift)` }} onClick={() => props.showRewards()} />
                    </div>
                    <div className={style.greetingText + ' home-text-main'}>{greeting().main}</div>
                    <div className={style.greetingText + ' home-text-secondary'}>{greeting().secondary}</div>
                </div>
                <div className={style.groupHalf}>
                    <HomeDataPoint 
                        title="Steps"
                        icon="steps"
                        value={((fetchSteps[0] || {}).value || 0).toLocaleString()}
                        highlightValue={changeSteps()}
                        highlightColor={changeSteps()[0] === '-' ? 'home-text-bad' : 'home-text-good'}
                        secondary="today"
                        onSelect={() => setShowWalkHistory(true)}
                    />
                    <HomeDataPoint
                        title="Points"
                        icon="points"
                        value={((fetchPoints[0] || {}).value || 0).toLocaleString()}
                        secondary={`${(fetchData.quarterPoints || 0).toLocaleString()} in Q${quarter()}`}
                        onSelect={() => setShowPointsHistory(true)}
                    />
                </div>
                <div className={style.groupFull}>
                    <HomeLeaderboard
                        title="Spotlight"
                        icon="spotlight"
                        data={leaderboardFeatured}
                        type="group"
                        quarterPoints={groupPoints}
                        onSelect={() => props.history.push('/groups')}
                    />
                </div>
                <div className={style.groupFull}>
                    <HomeLeaderboard
                        title="Leaderboard"
                        icon="award"
                        data={leaderboardGroup}
                        type="user"
                        limit={3}
                        quarterPoints={(fetchData.quarterPoints || 0)}
                        onSelect={() => props.history.push('/groups')}
                    />
                </div>
                <br />
                {/* <div className={statsClass}>
                    {stats.map(stat => box(stat))}
                </div> */}
            </div>
            {/* <ShareGoalProgress visible={showShare} onFinish={() => setShowShare(false)} navigationTitle={'Share Progress'} /> */}
            <NotificationModal notifications={notifications} />
            <ContactModal visible={showContact} />
            <ContactModal />
            
            {/* TODO: these cause performance issues, look into it */}
            {homeLoaded ? (
                <>
                    <HistoryModal visible={showWalkHistory} steps={fetchSteps} title={'Walk History'} kind={'Steps Walked'} onFinish={()=>{setShowWalkHistory(false);props.resync();}} />
                    <HistoryModal visible={showPointsHistory} steps={fetchPoints} title={'Points History'} kind={'Points Earned'} onFinish={()=>{setShowPointsHistory(false);props.resync();}} />
                </>
            ) : null}
        </Navigation>
    );

};

export default Home;
