
import { useEffect } from 'react';
import HomeCard from '../HomeCard';
import style from './style.module.css';

const HomeLeaderboard = props => {

    const rank = index => ({
        0: 'Gold',
        1: 'Silver',
        2: 'Bronze'
    })[index] || '';

    const data = (props.data || []).slice(0, props.limit || 10000);

    const userItem = (data, index) => (
        <div className={style.item + ' home-border-list'}>
            <div className={style.number + ' home-text-accent'}>{index + 1}</div>
            <div className={style.photo} style={data.secondaryInfo && data.secondaryInfo.photo ? { backgroundImage: 'url(' + data.secondaryInfo.photo + ')' } : {}} />
            <div className={style.info}>
                <div className={style.name + ' home-text-main'}>{data.firstName} {data.lastName}</div>
                {rank(index) ? <div className={style.rank + ' home-text-secondary'}>{rank(index)}</div> : null}
            </div>
            <div className={style.points + ' home-text-accent'}>{data.quarterPoints.toLocaleString()}</div>
        </div>
    );

    const groupItem = (data, index) => (
        <div className={style.item + ' home-border-list'}>
            <div className={style.number + ' home-text-accent'}>{index + 1}</div>
            <div className={style.info}>
                <div className={style.name + ' home-text-main'}>{data.name}</div>
            </div>
            <div className={style.points + ' home-text-accent'}>{data.quarterPoints.toLocaleString()}</div>
        </div>
    );

    const tip = () => {
        const points = props.quarterPoints || 0;
        const competitors = data.filter(group => points < group.quarterPoints);
        
        const next = competitors.length ? competitors[competitors.length - 1] : null;
        if (!next) return 'You\'re doing great!';

        return `Get ${(next.quarterPoints - points).toLocaleString()} more points to catch ${next.firstName || next.name}!`;
    };
    
    return (
        <HomeCard title={props.title} icon={props.icon} onSelect={props.onSelect}>
            {data.map(props.type === 'user' ? userItem : groupItem)}
            <div className={style.tip}>
                <div className={style.tipIcon} style={{ backgroundImage: `var(--appearance-lightbulb)` }} />
                <div className={style.tipText + ' home-text-main'}>{tip()}</div>
            </div>
        </HomeCard>
    );

};

export default HomeLeaderboard;
