
import { request } from './';

const fetchLeaderboards = async group => {
    const fetchResult = await request('/groups/leaderboards/local', { group });
    return fetchResult.data;
};

const fetchFeatured = async () => {
    const fetchResult = await request('/groups/leaderboards/featured', {});
    return fetchResult.data;
};

const fetchGlobals = async () => {
    const fetchResult = await request('/groups/leaderboards/global', {});
    return fetchResult.data;
};

const addGroup = async (data) => {
    const fetchResults = await request('/groups/add', data)
    return fetchResults.data;
}

const searchGroups = async (data) => {
    const fetchResults = await request('/groups/search', data)
    return fetchResults.data;
};
const deleteGroup = async (data) => {
    const fetchResults = await request('/groups/delete', data)
    return fetchResults.data;
}

const groupRequest = async (data) =>{
    const fetchResults = await request('/groups/members/requests', data)
    return fetchResults.data;
}
const joinGroup = async (data) =>{
    const fetchResults = await request('/groups/join', data)
    return fetchResults.data;
}
const acceptUserToGroup = async (data) =>{
    const fetchResults = await request('/groups/accept', data)
    return fetchResults.data;
}
const fetchAnalytics = async (data) => {
    const fetchResults = await request('/analytics/aggregate', data)
    return fetchResults.data
}
const kickUser = async (data) =>{
    const fetchResults = await request('/groups/kick', data)
    return fetchResults.data;
}
const leaveGroup = async (data) =>{
    const fetchResults = await request('/groups/leave', data)
    return fetchResults.data;
}
export default { fetchLeaderboards,kickUser,leaveGroup, fetchFeatured, fetchGlobals, addGroup, searchGroups, deleteGroup,groupRequest, joinGroup, acceptUserToGroup, fetchAnalytics };
