
import style from './style.module.css';

const HomeCard = props => (
    <div className={style.HomeCard + ' home-card-background home-card-border'} onClick={() => props.onSelect && props.onSelect()}>
        <div className={style.info}>
            <div className={style.icon} style={{ backgroundImage: `var(--appearance-${props.icon || ''})` }} />
            <div className={style.title + ' home-text-secondary'}>{props.title || ''}</div>
            <div className={style.arrow} />
        </div>
        <div className={style.content}>
            {props.children}
        </div>
    </div>
);

export default HomeCard;
